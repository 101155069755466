// == BANNER == //
#page-banner { min-height: 200px; text-align: center; background: $color-action; position: relative;
	img { width: 100%; height: auto; display: block; }
	.faces { object-fit: cover; object-position: top; }
}

// - Front - //
body.path-front {
	#page-banner {
		.faces { min-height: 200px; min-height: 200px; }
		.svg { max-width: 240px;
			@include center(true, true);
			@include smart {
				max-width: 400px;
			}
			@include tablet {
				margin-top: -17px;
			}
			@include desktop {
				max-width: 600px; margin-top: -34px;
			}
		}
	}
}
// - Not Front - //
body:not(.path-front) {
	#page-banner { max-height: 100px; min-height: 0;
		.faces { max-height: 100px; min-height: 0; }
		h1 { width: 100%; margin: 0; font-size: 24px;
			@include mainFont(200);
			@include center(true, true);
			@include smart {

			}
			@include tablet {
				font-size: 36px;
			}
		}
	}
}