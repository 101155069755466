// == BASE == //
// - All - //
* { outline: none;
	@include boxSize(border-box);
}

// - Background - //
body { background: $color-light; }

// - Links - //
a { color: $color-action;
	&:hover { text-decoration: none; }
}
a.button-ghost { border: 2px solid $color-action; text-decoration: none; text-transform: uppercase; line-height: 32px; height: 34px; display: inline-block; font-size: 12px; padding: 0 20px; margin: 0 0 4px 0; background: $color-clear;
	@include boxSize(border-box);
	@include mainFont(600);
	@include rounded(2px, 2px, 2px, 2px);
	@include transition(all, 0.2s, ease-in-out);
	&:hover { color: $color-clear; background: $color-action; }
}
a.button-ghost-image { text-decoration: none; text-align: center; text-transform: uppercase; height: auto; display: inline-block; font-size: 12px; padding: 0; margin: 20px 10px 0 10px; background: $color-clear;
	@include mainFont(600);
	img { border: 2px solid $color-dark; float: left; display: block;
		@include rounded(2px, 2px, 2px, 2px);
		@include boxSize(border-box);
	}
	span { float: left; clear: left; width: 100%; color: $color-dark;
		@include transition(color, 0.2s, ease-in-out);
	}
	&:hover {
		span { color: $color-action; }
	}
}
a.beta-capsule { position: relative; margin-right: 27px; text-decoration: line-through !important;
	&:after { content: 'BETA'; position: absolute; background: #E9AE4E; height: 16px; font-size: 9px; line-height: 16px; top: -5px; color: $color-dark; padding: 0 6px; text-align: center; text-decoration: none;
		@include rounded(8px, 8px, 8px, 8px);
		@include mainFont(600);
	}
}

// - Lists - //
// default
ul, ol { padding: 0 0 0 28px; }

// - Forms - //
// form
form {}

// input
input[type="text"],
input[type="password"],
input[type="email"],
input[type="url"],
input[type="date"],
input[type="month"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="week"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="color"] {
	&:focus {}
	&[disabled] { cursor: not-allowed; }
}

input[type="file"],
input[type="radio"],
input[type="checkbox"] {
	&:focus {}
}

input:not([type]) {
	&:focus {}
	&[disabled] { cursor: not-allowed; }
}

// textarea
textarea {
	&:focus {}
	&[disabled] { cursor: not-allowed; }
}

// label
label {}

// fieldset
fieldset {}

// legend
legend {}

// select
select { @include boxSize(border-box);
	&:focus {}
	&[disabled] { cursor: not-allowed; }
	&[multiple] { height: auto; }
}

// optgroup
optgroup {}

// option
option {}

// button
button {}

// output
output {}

// placeholder
@mixin placeholder {}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
	@include placeholder;
}
::-moz-placeholder { /* Firefox 19+ */
	@include placeholder;
}
:-ms-input-placeholder { /* IE 10+ */
	@include placeholder;
}
:-moz-placeholder { /* Firefox 18- */
	@include placeholder;
}

// table
table { width: 100%; border-top: 1px solid $color-grey; font-size: 12px;
	tr {}
	th { background: $color-clear-dark; }
	td { background: $color-clear; }
	th, td { border-right: 1px solid $color-grey; border-bottom: 1px solid $color-grey; padding: 4px 8px;
		&:nth-child(2n+1) { border-left: 1px solid $color-grey; width: 180px; vertical-align: top; }
	}
}

// - Snippets - //
// center align elements within parent
.center-hori { position: relative;
	> div,
	> i,
	> a,
	> p,
	> span,
	> img { display: block; margin: 0;
		@include center(true, false);
	}
}
.center-vert { position: relative;
	> div,
	> i,
	> a,
	> p,
	> span,
	> img { display: block; margin: 0;
		@include center(false, true);
	}
}
.center-both { position: relative;
	> div,
	> i,
	> a,
	> p,
	> span,
	> img { display: block; margin: 0;
		@include center(true, true);
	}
}