// == MAIN == //
#page-main {}

// - Introduction container - //
.introduction-container { margin-top: 40px; margin-bottom: 40px;
	.jcol { background: $color-clear; text-align: center; padding: 40px;
		@include rounded(2px, 2px, 2px, 2px);
		@include boxShadow(0px, 0px, 48px, 10px, rgba(0, 0, 0, 0.2));
	}
	@include tablet {
		margin-top: -34px;
	}
	@include desktop {
		margin-top: -68px;
	}
	h1 { margin: 0; }
	p { font-size: 16px; }
	a.button-ghost { margin-top: 10px; }
}

// - Layout container - //
.layout-container { text-align: center;
	img { margin-top: 20px; }
	h6 { margin-top: 0; }
}

// - Examples container - //
.examples-container { text-align: center; background: $color-clear; height: auto;
	h3 { text-transform: uppercase; color: $color-dark; line-height: normal;
		@include mainFont(200);
	}
	&.examples-container-1 { padding: 40px 0 0 0; }
	&.examples-container-2 { padding: 20px 0 20px 0; }
	&.examples-container-3 { padding: 20px 0 60px 0; }
}

// - Features container - //
.features-container { text-align: center; background: $color-clear; padding: 20px 0 10px 0;
	.features-flex { display: flex; width: 100%; flex-wrap: wrap; align-items: stretch;
		.feature { background: $color-light; padding: 20px 20px 10px 20px; flex: 1 0 auto; margin: 10px; width: 50%; display: flex; align-items: center;
			& > div { width: 100%; }
			i { border: 2px solid $color-action; height: 50px; width: 50px; line-height: 46px; color: $color-action; font-size: 28px; text-align: center;
				@include rounded(50%, 50%, 50%, 50%);
			}
			h6 { margin-bottom: 0; margin-top: 10px; }
		}
		@include tablet {
			.feature { width: 40%; }
		}
		@include desktop {
			.feature { width: 30%; }
		}
		@include large {
			.feature { width: 20%; }
		}
	}
}

// - iFrame container - //
.iframe-container { padding: 0; background: $color-clear; margin-top: 70px;
	iframe { width: 100%; height: calc(100vh - 70px); border: none; padding: 0; }
}
