// == FUNCTIONS == //
// - jAccordion - //
ul.jaccordion { list-style: none; margin: 0; padding: 0;
	@include backface;
	& > li { margin-bottom: 2px; width: 100%; float: left;
		div.itemBar { width: 100%; height: auto; padding: 14px 34px 14px 20px; margin: 0; position: relative; cursor: pointer; line-height: normal; background: $color-grey; color: $color-clear; font-size: 14px;
			@include backface;
			span { display: inline; }
			&:after { content: '\f067'; font-family: 'FontAwesome'; position: absolute; right: 20px; top: calc(50% - 5px); height: 16px; color: $color-clear; font-size: 14px; }
		}
		div.itemBar.is-expanded { background: $color-primary;
			&:after { content: '\f068'; }
		}
		div.itemContent { display: none; width: 100%; padding: 0;
			@include backface;
		}
	}
	// nested accordion
	ul.jaccordion {
		& > li {
			&:first-child { margin-top: 2px; }
			&:last-child { margin-bottom: 0; }
			div.itemBar { background: lighten($color-grey, 10%); }
			div.itemBar.is-expanded { background: lighten($color-primary, 10%); }
		}
	}
}

// - jTabber - //
ul.jtabber { list-style: none; width: 100%; margin: 20px 0 0 0; float: left; padding: 0; border-bottom: 1px solid $color-action;
	li { float: left; padding: 0 12px; margin-right: 4px; height: 31px; line-height: 31px; background: $color-clear-dark;
		&:hover { color: $color-clear; background: $color-dark; cursor: pointer; }
	}
	li.open { color: $color-clear; background: $color-action; }
}
ul.jtabberContent { list-style: none; padding: 0; margin: 0; border: 1px solid $color-clear-dark; border-top: none; float: left; width: 100%;
	li { padding: 12px; float: left; display: none; margin: 0; width: 100%;
		p:first-child { margin-top: 0; }
		p:last-child { margin-bottom: 0; }
	}
	li.open { display: block; border-top: 3px solid $color-action; }
	li.active { border-top: 3px solid $color-action; }
}