// == DOCUMENTATION == //
nav#side-nav { position: relative; top: 0; padding: 0; width: $sidebar-width-left; left: -20px;
	ul { margin: 0; padding: 0;
		li { list-style: none; font-size: 16px;
			a { display: block; width: 100%; text-decoration: none; padding: 0 20px; line-height: 34px; background: none; color: $color-grey;
				@include boxSize(border-box);
				@include transition(all, 0.2s, ease-in-out);
				@include mainFont(400);
				&:hover { color: $color-dark; }
			}
			a.active { background: $color-dark; color: $color-clear; }
			ul {
				li { font-size: 14px;
					a { padding: 0 30px; }
					ul {
						li { font-size: 13px;
							a { padding: 0 40px; }
						}
					}
				}
			}
			&.heading { font-size: 20px; padding: 0 20px; line-height: 48px; color: $color-dark;
				@include mainFont(600);
			}
		}
	}
	&.stick { position: fixed; top: 70px; left: 0; }
}

section.section { width: 100%; float: left;
	&:last-child { margin-bottom: 40px; }
	ul:not(.linenums), ol:not(.linenums) { font-size: 14px; }
	img { width: 60%; display: block; height: auto; margin: 0 auto; }
}