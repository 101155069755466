// == NAVIGATION == //
// - Main Menu - //
.main-nav { display: none; width: 100%; float: left; position: relative; overflow-y: scroll;
	// first level
	ul { margin: 0; padding: 0; background: $color-clear; width: 100%; float: left;
		li { list-style: none; float: left; width: 100%; line-height: 48px; border-bottom: 1px solid lighten($color-grey, 30%); position: relative;
			a { color: $color-dark; text-decoration: none; display: block; padding: 0 20px; text-transform: uppercase; font-size: 14px;
				@include mainFont(500);
			}
			&.has-child {
				sub { right: 0; height: 48px; line-height: 48px; width: 48px; background: none; display: block; position: absolute; bottom: auto; top: 0; text-align: center; cursor: pointer; font-size: 1em;
					i { color: $color-action; }
				}
			}
			&:last-child { background: $color-action;
				a { color: $color-clear; }
			}
			// second level
			ul { background: darken($color-clear, 2%); display: none;
				li {
					&:first-child { border-top: 1px solid lighten($color-grey, 30%); }
					a { padding: 0 30px; color: $color-action; font-size: 12px;
						@include mainFont(400);
					}
					&:last-child { border-bottom: none; background: none;
						a { color: $color-action; }
					}
					// third level
					ul { background: darken($color-clear, 4%); display: none;
						li {
							a { padding: 0 40px; }
						}
					}
				}
			}
			ul.active { display: block; }
		}
	}
}
@include desktop {
	html:not([data-browser*="iPad"]):not([data-browser*="Android"]) {
		.main-nav { display: block; overflow-y: visible; float: right;
			// first level
			ul { background: none; width: auto; float: none;
				li { width: auto; line-height: 34px; border-bottom: none; margin: 18px 10px;
					a { display: block; float: left; height: 34px; padding: 0; color: $color-clear; font-size: 12px;
						@include mainFont(200);
						@include transition(color, 0.1s, ease-in-out);
						&.active { color: $color-action !important; }
					}
					sub { font-size: 9px; line-height: normal; right: 8px; height: auto; width: 20px; padding-top: 29px;
						i { color: $color-clear;
							@include transition(color, 0.1s, ease-in-out);
						}
					}
					&:hover {
						& > a { color: $color-action; }
						sub {
							i { color: $color-action; }
						}
						ul { display: block; }
					}
					&:last-child { margin-right: 0; background: none;
						a { background: transparentize($color-action, 0); padding: 0 20px;
							@include mainFont(600);
							@include rounded(2px, 2px, 2px, 2px);
							@include transition(background, 0.2s, ease-in-out);
							&:hover { color: $color-clear; background: transparentize($color-action, 0.1); }
						}
					}
					// second level
					ul { position: absolute; left: 0; background: $color-clear; top: $header-height-desktop;
						@include boxShadow(0px, 0px, 48px, 10px, rgba(0, 0, 0, 0.2));
						li { width: 100%; line-height: 30px; border-bottom: 1px solid lighten($color-grey, 30%); margin: 0;
							&:last-child { border-bottom: none; }
							a { white-space: nowrap; height: 30px; color: $color-dark;
								@include mainFont(400);
							}
							sub { line-height: 30px; padding-top: 0; height: 30px; width: 30px; }
							// third level
							ul { display: none !important; left: 100%; top: -1px;
								&.wrapper-3-fit { left: auto; right: 100%; }
							}
							&:hover {
								ul { display: block !important;
									li {
										a { padding: 0 10px; }
									}
								}
							}
						}
						&.wrapper-2-fit { left: auto; right: 0; }
					}
					&.has-child { margin: 0; padding: 18px 10px;
						a { padding: 0 20px 10px 5px; }
						// second level
						ul {
							li {
								a { padding: 0 16px; width: 100%; }
								&.has-child {
									a { padding: 0 30px 0 10px; }
								}
								&:last-child {
									a { color: $color-dark; background: none;
										@include rounded(0, 0, 0, 0);
										@include mainFont(400);
										@include transition(color, 0.1s, ease-in-out);
									}
									&:hover {
										a { color: $color-action; }
									}
								}
								// third level
								ul {
									li {
										a { padding: 0 10px !important; }
									}
								}
							}
						}
					}
				}
			}
			&.menu-inline { width: auto; float: none; position: absolute; height: $header-height-desktop - $header-strip-height-desktop; right: 0;
				// first level
				ul { height: 100%;
					li { height: 100%;
						&:before { content: ''; display: inline-block; height: 100%; vertical-align: middle; }
						a { color: $color-secondary; vertical-align: middle; }
						sub { top: calc(50% - 4px); padding-top: 0;
							i { color: $color-secondary; }
						}
						// second level
						ul { height: auto; top: 100%;
							li { height: auto;
								&:before { display: none; }
								a { color: $color-action; }
								sub { top: 0;
									i { color: $color-action; }
								}
								// third level
								ul { top: -1px; }
							}
						}
					}
				}
			}
		}
		// - Container Style Conditionals - //
		.jcontainer-fluid {
			.main-nav.menu-inline > ul > li:last-child { margin-right: 25px; }
			&.jcontainer-set {
				@include large {
					.main-nav.menu-inline > ul > li:last-child { margin-right: 5px; }
				}
			}
		}
	}
}

// - Main Menu Toggler - //
.toggler { display: block; position: absolute; top: 0; right: 0; text-align: center; height: 70px; line-height: 70px; width: 70px; cursor: pointer;
	div { width: 24px; position: absolute; height: 15px; display: block; top: 30px; left: 23px; }
	span { display: block; position: absolute; height: 1px; width: 100%; background: $color-clear; left: 0;
		@include transition(all, 0.25s, ease-in-out);
		@include rotate(0);
		&:nth-child(1) { top: 0; }
		&:nth-child(2), &:nth-child(3) { top: 7px; }
		&:nth-child(4) { top: 14px; }
	}
}
.toggler.open {
	span {
		&:nth-child(1) { top: 14px; width: 0%; left: 50%; }
		&:nth-child(2) {
			@include rotate(45);
		}
		&:nth-child(3) {
			@include rotate(-45);
		}
		&:nth-child(4) { top: 14px; width: 0%; left: 50%; }
	}
}
@include desktop {
	html:not([data-browser*="iPad"]):not([data-browser*="Android"]) {
		.toggler { display: none; }
	}
}

// - Blackout - //
.blackout { display: none; position: fixed; height: 100%; width: 100%; background: transparentize(#000000, 0.6); z-index: 1; }
@include desktop {
	html:not([data-browser*="iPad"]):not([data-browser*="Android"]) {
		.blackout { display: none !important; }
	}
}
