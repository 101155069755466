// == MIXINS == //
// - Box Sizing - //
@mixin boxSize($value) {
    -webkit-box-sizing: $value;
    -moz-box-sizing: $value;
    box-sizing: $value;
}

// - Box Shadow - //
@mixin boxShadow($hori, $vert, $blur, $spread, $color) {
    -moz-box-shadow:    $hori $vert $blur $spread $color;
    -webkit-box-shadow: $hori $vert $blur $spread $color;
    box-shadow:         $hori $vert $blur $spread $color;
}

// - Backface Visibility - //
@mixin backface {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	-o-backface-visibility: hidden;
	backface-visibility: hidden;
}

// - Transition - //
@mixin transition($property, $duration, $function, $delay : 0s) {
    -webkit-transition: $property $duration $function $delay;
    -moz-transition: $property $duration $function $delay;
    -ms-transition: $property $duration $function $delay;
    -o-transition: $property $duration $function $delay;
    transition: $property $duration $function $delay;
}

// - Border Radius - //
@mixin rounded($topLeft, $topRight, $bottomRight, $bottomLeft) {
	-webkit-border-top-left-radius: $topLeft;
	-webkit-border-top-right-radius: $topRight;
	-webkit-border-bottom-right-radius: $bottomRight;
	-webkit-border-bottom-left-radius: $bottomLeft;

	-moz-border-radius-topleft: $topLeft;
	-moz-border-radius-topright: $topRight;
	-moz-border-radius-bottomright: $bottomRight;
	-moz-border-radius-bottomleft: $bottomLeft;

	border-top-left-radius: $topLeft;
	border-top-right-radius: $topRight;
	border-bottom-right-radius: $bottomRight;
	border-bottom-left-radius: $bottomLeft;
}

// - Transform - //
@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}
// transform : rotate
@mixin rotate($deg) {
    @include transform(rotate(#{$deg}deg));
}
// transform : scale
@mixin scale($scale) {
    @include transform(scale($scale));
}
// transform : translate
@mixin translate($x, $y) {
    @include transform(translate($x, $y));
}
// transform : skew
@mixin skew($x, $y) {
    @include transform(skew(#{$x}deg, #{$y}deg));
}
// transform : transform origin
@mixin transform-origin($origin) {
    -moz-transform-origin: $origin;
    -o-transform-origin: $origin;
    -ms-transform-origin: $origin;
    -webkit-transform-origin: $origin;
    transform-origin: $origin;
}

// - Blur - //
@mixin blur($amount) {
    filter: blur($amount);
    -webkit-filter: blur($amount);
}

// - Gradient - //
@mixin gradient($from, $to, $vertical: true) {
    @if ($vertical) {
        background: -moz-linear-gradient(top,  $from 0%, $to 100%);
        background: -webkit-linear-gradient(top,  $from 0%, $to 100%);
        background: linear-gradient(to bottom,  $from 0%, $to 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#000000',GradientType=0 );
    } @else {
        background: -moz-linear-gradient(left,  $to 0%, $from 100%);
        background: -webkit-linear-gradient(left,  $to 0%,$from 100%);
        background: linear-gradient(to right,  $to 0%,$from 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#000000', endColorstr='#00000000',GradientType=1 );
    }
}

// - Center Align - //
// the parent needs to be 'position: relative' and have a specific set height in px
@mixin center($horizontal: true, $vertical: true) {
    position: absolute;
    @if ($horizontal and $vertical) {
        top: 50%;
        left: 50%;
        @include translate(-50%, -50%);
    } @else if ($horizontal) {
        left: 50%;
        @include translate(-50%, 0);
    } @else if ($vertical) {
        top: 50%;
        @include translate(0, -50%);
    }
}

// - Keyframe - //
// for animations
@mixin keyframes($animationName) {
    @-webkit-keyframes #{$animationName} {
        @content;
    }
    @-moz-keyframes #{$animationName} {
        @content;
    }
    @-o-keyframes #{$animationName} {
        @content;
    }
    @keyframes #{$animationName} {
        @content;
    }
}