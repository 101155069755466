// == COLORS == //
// - Main Colors - //
$color-primary : #6A3684;
$color-secondary : #FDD454;

$color-dark: #232323;
$color-light: #ecf0ea;
$color-clear : #FFFFFF;
$color-clear-dark: #d1d5d0;
$color-action: #71c852;
$color-grey : #808080;
// - Message Colors - //
$color-success : #69BC3C;
$color-error : #E04D4D;
$color-warning : #EBB35A;
$color-info : #62a9de;

// == SETTINGS == //
// - Header - //
$header-height-mobile : 70px;
$header-strip-height-desktop : 38px;
$header-height-desktop : 70px;
// - Container Background - //
$container-bg : none;
// - Page Margin - //
$page-margin : 20px;
// - Gutter (horizontal space between columns) - //
$gutter : 20px;
// - Breakpoints - //
$feature-width : 320px;
$smart-width : 480px;
$tablet-width : 768px;
$desktop-width : 960px;
$large-width : 1200px;
// - Sidebar - //
$sidebar-width-left-small : 200px;
$sidebar-width-right-small : 350px;
$sidebar-width-left : 300px;
$sidebar-width-right : 350px;
$content-bg : $color-light;
$sidebar-bg : $color-clear-dark;

// == BASE == //
// - Mixins - //
@import 'base/mixins';
// - Typography - //
@import 'base/typography';
// - Base - //
@import 'base/base';
// - Grid - //
@import 'base/grid';
// - Layout - //
@import 'base/layout';
// - Navigation - //
@import 'base/navigation';
// - Functions - //
@import 'base/functions';

// == THEME == //
// - Header - //
@import 'theme/header';
// - Banner - //
@import 'theme/banner';
// - Main - //
@import 'theme/main';
// - Footer - //
@import 'theme/footer';

// == DOCUMENTATION == //
@import 'documentation/documentation';