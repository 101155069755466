$col-margin : calc(#{$gutter} / 2); // DO NOT CHANGE THIS //

// == BREAKPOINTS == //
@mixin feature {
	@media (min-width: #{$feature-width}) {
		@content;
	}
}
@mixin smart {
	@media (min-width: #{$smart-width}) {
		@content;
	}
}
@mixin tablet {
	@media (min-width: #{$tablet-width}) {
		@content;
	}
}
@mixin desktop {
	@media (min-width: #{$desktop-width}) {
		@content;
	}
}
@mixin large {
	@media (min-width: #{$large-width}) {
		@content;
	}
}

// == COLUMN GENERATOR == //
@mixin generateColumns($col-infix, $col-hop: false, $col-pop: false, $col-flush: false, $col-count: 12) {
	@for $i from 1 through $col-count {
		@each $infix in $col-infix {
			
			$marginNormal : calc(((100% / #{$col-count}) * #{$i}) + #{$col-margin});
			$marginFlush : calc(((100% / #{$col-count}) * #{$i}));
			
			@if $col-hop == false and $col-pop == false {
				@if $col-flush {
					// normal + flush
					.jcol-#{$infix + "-" + $i}.jcol-flush { width: calc((100% / #{$col-count}) * #{$i}); }
				} @else {
					// normal
					.jcol-#{$infix + "-" + $i} { width: calc(((100% / #{$col-count}) * #{$i}) - #{$gutter}); }
				}
			} @elseif $col-hop {
				@if $col-flush {
					// hop + flush
					.jcol-#{$infix + "-hop-" + $i}.jcol-flush { margin-left: $marginFlush; }
				} @else {
					// hop
					.jcol-#{$infix + "-hop-" + $i} { margin-left: $marginNormal; }
				}
			} @elseif $col-pop {
				@if $col-flush {
					// pop + flush
					.jcol-#{$infix + "-pop-" + $i}.jcol-flush { margin-right: $marginFlush; }
				} @else {
					// pop
					.jcol-#{$infix + "-pop-" + $i} { margin-right: $marginNormal; }
				}
			}
		}
	}
}

// == GRID == //
.jcontainer { position: relative; width: 100%; margin: 0 auto; padding: 0 $page-margin; float: left; clear: left; background: $container-bg; // theme stuff included
	.jrow { width: 100%; float: left;
		.jcol { float: left; margin: 0 $col-margin; width: calc(100% - #{$gutter}); }
		.jcol-flush { margin: 0; width: 100%; }
		.jcol-nest { padding: 0 $col-margin; }
		.jcol-nest-flush { padding: 0; }
	}
}
.jcontainer-has-sidebar,
.jcontainer-has-sidebar-both { padding: 0 !important;
	.jcontent { width: 100%; float: left; background: $content-bg; padding: 0 $page-margin; position: relative; }
	.jsidebar { width: 100%; float: left; background: $sidebar-bg; padding: 0 $page-margin; position: relative; }
}

// ==== TEMPORARY ==== //
.jheader.remove_this { position: relative; z-index: 0;
	.jcontainer { background: $color-primary !important;
		.jcol {
			img { margin: 100px auto; width: 100%; max-width: 400px; height: auto; display: block; }
		}
	}
}

.jcontainer.remove_this { background: $color-clear; z-index: 0; position: relative;
	.jrow {
		.jcol { background: lighten($color-grey, 15%); text-align: center; height: 30px; line-height: 30px; margin-top: $col-margin; margin-bottom: $col-margin;
			@include rounded(4px, 4px, 4px, 4px);
		}
		.jcol-flush { background: $color-grey; }
		.jcol-nest { height: auto; padding: $col-margin;
			.jrow {
				.jcol { background: $color-primary; color: $color-clear; }
				.jcol-flush { background: $color-secondary; }
			}
		}
		.jcol-nest-flush { padding: $col-margin 0; }
	}
}
.jcontainer-has-sidebar.remove_this, .jcontainer-has-sidebar-both.remove_this {
	.jrow {
		.jcol { background: $color-primary; color: $color-clear; }
		.jcol-flush { background: $color-secondary; }
	}
}

// ======== MEDIA QUERIES ======== //
// - Feature Phones - //
@include feature {

// == GRID == //
.jcontainer {
	.jrow {
		@include generateColumns($col-infix: fea, $col-hop: false, $col-pop: false, $col-flush: false);
		@include generateColumns($col-infix: fea, $col-hop: true, $col-pop: false, $col-flush: false);
		@include generateColumns($col-infix: fea, $col-hop: false, $col-pop: true, $col-flush: false);
		@include generateColumns($col-infix: fea, $col-hop: false, $col-pop: false, $col-flush: true);
		@include generateColumns($col-infix: fea, $col-hop: true, $col-pop: false, $col-flush: true);
		@include generateColumns($col-infix: fea, $col-hop: false, $col-pop: true, $col-flush: true);
	}
}

}

// - Smart Phones - //
@include smart {

$value : $smart-width - $gutter * 2;
$left-margin : $value / 2;

// == GRID == //
.jcontainer { width: $value; left: 50%; margin-left: -$left-margin;
	.jrow {
		@include generateColumns($col-infix: sma, $col-hop: false, $col-pop: false, $col-flush: false);
		@include generateColumns($col-infix: sma, $col-hop: true, $col-pop: false, $col-flush: false);
		@include generateColumns($col-infix: sma, $col-hop: false, $col-pop: true, $col-flush: false);
		@include generateColumns($col-infix: sma, $col-hop: false, $col-pop: false, $col-flush: true);
		@include generateColumns($col-infix: sma, $col-hop: true, $col-pop: false, $col-flush: true);
		@include generateColumns($col-infix: sma, $col-hop: false, $col-pop: true, $col-flush: true);
	}
}
.jcontainer-fluid { width: 100% !important; margin-left: -50% !important; }

}

// - Tablet - //
@include tablet {

$value : $tablet-width - $gutter * 2;
$left-margin : $value / 2;

// == GRID == //
.jcontainer { width: $value; margin-left: -$left-margin;
	.jrow {
		@include generateColumns($col-infix: tab, $col-hop: false, $col-pop: false, $col-flush: false);
		@include generateColumns($col-infix: tab, $col-hop: true, $col-pop: false, $col-flush: false);
		@include generateColumns($col-infix: tab, $col-hop: false, $col-pop: true, $col-flush: false);
		@include generateColumns($col-infix: tab, $col-hop: false, $col-pop: false, $col-flush: true);
		@include generateColumns($col-infix: tab, $col-hop: true, $col-pop: false, $col-flush: true);
		@include generateColumns($col-infix: tab, $col-hop: false, $col-pop: true, $col-flush: true);
	}
}
.jcontainer-has-sidebar,
.jcontainer-has-sidebar-both {
	.jcontent-left { width: calc(100% - #{$sidebar-width-right-small}); padding: 0 calc(#{$page-margin} / 2) 0 $page-margin; }
	.jcontent-right { width: calc(100% - #{$sidebar-width-left-small}); padding: 0 $page-margin 0 calc(#{$page-margin} / 2); }
	.jcontent-middle { width: calc(100% - ( #{$sidebar-width-left-small} + #{$sidebar-width-right-small} )); padding: 0 calc(#{$page-margin} / 2) 0 calc(#{$page-margin} / 2); }
	.jsidebar-left { width: $sidebar-width-left-small; padding: 0 calc(#{$page-margin} / 2) 0 $page-margin; }
	.jsidebar-right { width: $sidebar-width-right-small; padding: 0 $page-margin 0 calc(#{$page-margin} / 2); }
}

}

// - Desktop - //
@include desktop {

$value : $desktop-width - $gutter * 2;
$left-margin : $value / 2;

// == GRID == //
.jcontainer { width: $value; margin-left: -$left-margin;
	.jrow {
		@include generateColumns($col-infix: des, $col-hop: false, $col-pop: false, $col-flush: false);
		@include generateColumns($col-infix: des, $col-hop: true, $col-pop: false, $col-flush: false);
		@include generateColumns($col-infix: des, $col-hop: false, $col-pop: true, $col-flush: false);
		@include generateColumns($col-infix: des, $col-hop: false, $col-pop: false, $col-flush: true);
		@include generateColumns($col-infix: des, $col-hop: true, $col-pop: false, $col-flush: true);
		@include generateColumns($col-infix: des, $col-hop: false, $col-pop: true, $col-flush: true);
	}
}
.jcontainer-has-sidebar,
.jcontainer-has-sidebar-both {
	.jcontent-left { width: calc(100% - #{$sidebar-width-right}); }
	.jcontent-right { width: calc(100% - #{$sidebar-width-left}); }
	.jcontent-middle { width: calc(100% - ( #{$sidebar-width-left} + #{$sidebar-width-right} )); }
	.jsidebar-left { width: $sidebar-width-left; }
	.jsidebar-right { width: $sidebar-width-right; }
}

}

// - Large Screens - //
@include large {

$value : $large-width - $gutter * 2;
$left-margin : $value / 2;

// == GRID == //
.jcontainer { width: $value; margin-left: -$left-margin;
	.jrow {
		@include generateColumns($col-infix: lar, $col-hop: false, $col-pop: false, $col-flush: false);
		@include generateColumns($col-infix: lar, $col-hop: true, $col-pop: false, $col-flush: false);
		@include generateColumns($col-infix: lar, $col-hop: false, $col-pop: true, $col-flush: false);
		@include generateColumns($col-infix: lar, $col-hop: false, $col-pop: false, $col-flush: true);
		@include generateColumns($col-infix: lar, $col-hop: true, $col-pop: false, $col-flush: true);
		@include generateColumns($col-infix: lar, $col-hop: false, $col-pop: true, $col-flush: true);
	}
}
.jcontainer-fluid.jcontainer-set { margin-left: -$left-margin !important; }
.jcontainer-set { width: $value !important; }

}