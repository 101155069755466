// == TYPOGRAPHY == //
// - Google Font - //
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,200,300,400,500,600,700,800,900');

@mixin mainFont($weight) {
	font-family: 'Montserrat', sans-serif;
	font-weight: $weight;
}

body { color: $color-grey; line-height: 1.6em;
	@include mainFont(300);
}

h1 { text-transform: uppercase; font-size: 30px; color: $color-dark; line-height: normal;
	@include mainFont(300);
}
h2 { text-transform: uppercase; font-size: 24px; color: $color-dark; line-height: normal;
	@include mainFont(200);
}
h6 { text-transform: uppercase; font-size: 14px; color: $color-action; line-height: normal;
	@include mainFont(600);
}
body.path-documentation {
	h2 { color: $color-action; text-transform: none; font-size: 28px; margin-bottom: 0; float: left;
		@include mainFont(700);
	}
	h3 { border-bottom: 1px solid transparentize($color-grey, 0.6); padding-bottom: 10px; }
}

p { font-size: 14px; }
strong { @include mainFont(500); }

pre, code { font-size: 12px;
	span.tag { color: $color-clear-dark; }
	span.pln, span.pun, span.typ { color: $color-clear; }
	span.atv, span.lit { color: $color-action; }
	span.atn, span.kwd { color: #E9AE4E; }
	span.com { color: transparentize($color-clear, 0.7); }
}
pre { margin: 0; padding: 10px !important; height: auto; width: 100%; overflow-y: scroll; line-height: 1.3em; display: block; background: $color-dark; border: 20px solid $color-clear-dark !important;
	@include boxSize(border-box);
	li.L0, li.L1, li.L2, li.L3, li.L4, li.L5, li.L6, li.L7, li.L8, li.L9 { list-style-type: decimal; }
	li.L1, li.L3, li.L5, li.L7, li.L9 { background: lighten($color-dark, 3%); }
}
code { background: $color-dark; padding: 2px 6px !important; }

.message { width: 100%; float: left; margin: 20px 0 0 0; padding: 10px 16px;
	@include boxSize(border-box);
	@include rounded(4px, 4px, 4px, 4px);
	@include mainFont(400);
	&.message-success { border: 2px solid $color-success; background: transparentize($color-success, 0.7); color: $color-success;
		a { color: $color-success; }
	}
	&.message-warning { border: 2px solid $color-warning; background: transparentize($color-warning, 0.7); color: $color-warning;
		a { color: $color-warning; }
	}
	&.message-error { border: 2px solid $color-error; background: transparentize($color-error, 0.7); color: $color-error;
		a { color: $color-error; }
	}
	&.message-info { border: 2px solid $color-info; background: transparentize($color-info, 0.7); color: $color-info;
		a { color: $color-info; }
	}
}