// == HEADER == //
#page-header { height: $header-height-mobile; background: $color-dark; z-index: 999; position: relative;
	// fixed header
	&.header-fixed { position: fixed; top: 0; }
}
// - Element after fixed header - //
.header-fixed-after { width: 100%; float: left; margin-top: $header-height-mobile; }
// - Header Main - //
.header-main { width: 100%; float: left;
	.jcontainer { background: none; }
	@include smart {
		.jcontainer { padding: 0; }
		.jcontainer-fluid { padding: 0 $page-margin; }
	}
	@include large {
		.jcontainer-fluid.jcontainer-set { padding: 0; }
	}
}

@include desktop {
	html:not([data-browser*="iPad"]):not([data-browser*="Android"]) {
		#page-header { height: $header-height-desktop; }
		// - Element after fixed header - //
		.header-fixed-after { margin-top: $header-height-desktop; }
	}
}

// - Logo - //
.logo { float: left; max-width: 140px; margin: 18px 0 17px 0;
	img { width: 100%; height: auto; display: block; }
}